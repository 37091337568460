// core components
import Headers from "components/Headers/Header";
import { promiseHandler } from "modules/formatters";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Modal,
  Row,
  Table,
} from "reactstrap";
import { getAllRegisteredProjects } from "services/http-service";
import EditProject from "./EditProject";
import { SelectProject } from "./ProjectSelectorFunctions";

const ProjectIndex = () => {
  const [projectsList, setProjectsList] = useState<any>(null);
  const [toBeDeletedAPI, setToBeDeletedAPI] = useState<any>(null);
  const [editProjectID, setEditProjectID] = useState<any>(null);
  // const [showAddProjectSection, setShowAddProjectSection] = useState(false);

  const navigate = useNavigate();

  const getProjects = useCallback(() => {
    async function wrapper() {
      const response = await getAllRegisteredProjects();
      setProjectsList(response.data);
      return response;
    }
    wrapper();
  }, []);

  useEffect(() => {
    const asyncCaller = async () => {
      await promiseHandler(() => getProjects());
    };
    asyncCaller();
  }, [getProjects]);

  return (
    <>
      <Headers title="Select Project" />
      {/* Page content */}
      <Container className="" fluid>
        {/* Dark table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <h3 className="text-white mb-0">Select Project</h3>
              </CardHeader>
              <CardBody>
                {projectsList ? (
                  <Table
                    className="align-items-center table-dark flush"
                    responsive
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Project ID</th>
                        <th scope="col">Project Name</th>
                        <th scope="col">Project Description</th>
                        <th scope="col">API Count</th>
                        <th scope="col">Date Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectsList.map((project: Iproject) => (
                        <>
                          <tr key={project.id}>
                            <td>
                              <Button
                                color="primary"
                                size="sm"
                                type="button"
                                onClick={() => setEditProjectID(project.id)}
                              >
                                Edit
                              </Button>
                              <Button
                                color="success"
                                size="sm"
                                type="button"
                                onClick={() => SelectProject(project, navigate)}
                              >
                                Select
                              </Button>
                            </td>
                            <td>{project.id}</td>
                            <td>{project.name}</td>
                            <td>{project.description}</td>
                            <td>{project.apis_count}</td>
                            <td>{project.date_created}</td>
                          </tr>
                          {editProjectID === project.id && (
                            <tr key={project.id + "edit"}>
                              <td colSpan={4}>
                                <EditProject projectId={project.id} />
                              </td>
                            </tr>
                          )}
                        </>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="text-white">
                    Oops!! No projects have been added so far for this user, <br /> please
                    contact admin{" "}
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
        {/* <Row>
          <Col className="mt-4">
            <Button onClick={() => setShowAddProjectSection(true)} color="primary">
              Add New Project
            </Button>
          </Col>
        </Row>
        {showAddProjectSection && <AddProject />} */}
      </Container>

      <Modal
        className="modal-dialog-centered modal-info"
        contentClassName="bg-gradient-teal"
        // isOpen={toBeDeletedAPI !== null}
        isOpen={false}
        toggle={() => setToBeDeletedAPI(null)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            Your attention is required
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setToBeDeletedAPI(null)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="ni ni-bell-55 ni-3x" />
            <h4 className="heading mt-4">You should read this!</h4>
            <p>You are about to delete this API. Are you sure?</p>
            {toBeDeletedAPI && (
              <code>
                ID: {toBeDeletedAPI.id} <br />
                Name: {toBeDeletedAPI.name} <br />
                Description: {toBeDeletedAPI.description} <br />
              </code>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setToBeDeletedAPI(null)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ProjectIndex;
