import { promiseHandler } from "modules/formatters";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "reactstrap";
import { getAllRegisteredProjects } from "services/http-service";
import { SelectProject } from "./ProjectSelectorFunctions";
import Styles from "./SelectProjectPage.module.scss";

const SelectProjectPage = () => {
  const [projectsList, setProjectsList] = useState<any>([]);
  const [modalState, setModalState] = useState<boolean>(true);
  const navigate = useNavigate();

  const getProjects = useCallback(() => {
    async function wrapper() {
      const response = await getAllRegisteredProjects();
      setProjectsList(response.data || []);
      return response;
    }
    wrapper();
  }, []);

  useEffect(() => {
    const asyncCaller = async () => {
      await promiseHandler(() => getProjects());
    };
    asyncCaller();
  }, [getProjects]);

  return (
    <>
      <Modal
        className="modal-dialog-centered modal-info"
        contentClassName="bg-gradient-teal"
        isOpen={modalState}
        toggle={() => setModalState(false)}
        size="lg"
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            Welcome to StartAppz API Gateway
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setModalState(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="ni ni-bell-55 ni-3x" />
            <h4 className="heading mt-4">Please select your Project</h4>
            <div className={Styles.projectHolders}>
              {projectsList.length === 0 ? (
                <div className="text-left">
                  {" "}
                  Oops!! No projects have been added so far for this user,{" "}
                  <br /> please contact admin{" "}
                </div>
              ) : (
                projectsList.map((project: any) => (
                  <Button
                    type="button"
                    onClick={() => SelectProject(project, navigate)}
                    key={project.name}
                    block
                  >
                    {project.name}
                  </Button>
                ))
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SelectProjectPage;
