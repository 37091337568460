import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Alert,
} from "reactstrap";
import { useState, useCallback, useEffect, useRef } from "react";
import { promiseHandler } from "modules/formatters";
import { patchOneRegisteredMock, getMockByAPIId } from "services/http-service";
import Header from "components/Headers/Header";
import MockHeadersControl from "./MockHeadersController";
import { useParams } from "react-router-dom";

const ModifyMock = () => {
  const [mockHttpStatus, setMockHttpStatus] = useState<any>(200);
  const [description, setDescription] = useState<any>("");
  const [mockJsonHeaders, setMockJsonHeaders] = useState<any>([
    { name: "", value: "" },
  ]);
  const [mockJsonBody, setMockJsonBody] = useState<any>([]);
  const [responseStatus, setResponseStatus] = useState<any>(null);
  const { apiId, mock_version } = useParams();
  const parametersRef = useRef("");

  const updateMockCaller = useCallback(
    async (data: any) => {
      try {
        const response = await patchOneRegisteredMock(
          apiId as string,
          mock_version as string,
          {
            mock_http_status: data.mockHttpStatus,
            description: data.description,
            mock_json_body: data.mockJsonBody,
            mock_json_headers: data.mock_json_headers,
          }
        );

        if (response.status >= 200 && response.status < 300) {
          setResponseStatus({
            success: true,
            message: "Mock Modified successfully",
          });
        } else {
          throw new Error("Something went wrong");
        }
      } catch (e: any) {
        setResponseStatus({
          success: false,
          message: e.message || "Something went wrong",
        });
      } finally {
        setTimeout(() => setResponseStatus(null), 5000);
      }
    },
    [apiId, mock_version]
  );

  useEffect(() => {
    const asyncCaller = async () => {
      const mockResponse = await getMockByAPIId(apiId as string);
      const mocks = mockResponse.data.availabile_mocks;
      const {
        description,
        mock_http_status,
        mock_json_headers,
        mock_json_body,
      } = mocks.find((mock: Imock) => mock.mock_version == mock_version);

      const modifiedMockHeaders: any = [];

      mock_json_headers &&
        Object.keys(mock_json_headers).forEach((key) => {
          modifiedMockHeaders.push({
            name: key,
            value: mock_json_headers[key],
          });
        });

      setMockHttpStatus(mock_http_status);
      setDescription(description);
      setMockJsonHeaders(modifiedMockHeaders);
      setMockJsonBody(mock_json_body);
      // @ts-ignore
      parametersRef.current.value = JSON.stringify(mock_json_body);
    };
    asyncCaller();
  }, []);

  const handleParameterValidation = (text: string) => {
    try {
      const c = JSON.parse(text);
      setMockJsonBody(c);
    } catch (e) {
      // console.log("Invalid JSON", e.message)
      setMockJsonBody(null);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // const [res] =
    await promiseHandler(() =>
      updateMockCaller({
        mockHttpStatus,
        description,
        mockJsonBody,
        // @ts-ignore
        mock_json_headers: mockJsonHeaders.reduce((v, c) => {
          const x = { ...v };
          x[c.name] = c.value;
          return x;
        }, {}),
      })
    );
  };

  return (
    <>
      <Header title={`Edit - MOCK: ${mock_version} of API ${apiId}`} />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Enter the values below</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    API Mock Information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="api-name"
                          >
                            Mock Version
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="api-name"
                            placeholder="Mock Version"
                            type="text"
                            disabled
                            // onChange={(e) => setMockVersion(e.target.value)}
                            value={mock_version}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="http-method"
                          >
                            Mock HTTP Status
                          </label>
                          <Input
                            id="http-method"
                            type="select"
                            className="form-control-alternative"
                            placeholder="GET"
                            onChange={(e) => setMockHttpStatus(e.target.value)}
                          >
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="404">404</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="description"
                          >
                            Mock Description
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="description"
                            placeholder="Write a user understandable description for this API"
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Mock JSON Header
                  </h6>
                  <MockHeadersControl
                    mockJsonHeaders={mockJsonHeaders}
                    setMockJsonHeaders={setMockJsonHeaders}
                  />
                  <h6 className="heading-small text-muted mb-4">
                    Mock JSON Body
                  </h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label>
                        Write down the parameters in a valid JSON format
                      </label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Enter the JSON value without double quotes"
                        rows="4"
                        type="textarea"
                        // @ts-ignore
                        innerRef={parametersRef}
                        onChange={(e) =>
                          handleParameterValidation(e.target.value)
                        }
                      />
                    </FormGroup>

                    {mockJsonBody !== null ? (
                      <Alert color="success">
                        <strong>Success!</strong> You are good at writing JSON
                        😁
                      </Alert>
                    ) : (
                      <Alert color="danger">
                        <strong>Attention!</strong> above text is not a valid
                        JSON 😓
                      </Alert>
                    )}
                  </div>
                  {responseStatus && (
                    <div className="pl-lg-4">
                      <Alert
                        color={responseStatus.success ? "success" : "danger"}
                      >
                        {responseStatus.message}
                      </Alert>
                    </div>
                  )}
                </Form>
              </CardBody>
            </Card>
            <div className="pt-4">
              <Button onClick={handleSubmit}>Update this Mock</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ModifyMock;
