import Header from "components/Headers/Header";
import { promiseHandler } from "modules/formatters";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { registerNewMock } from "services/http-service";
import MockHeadersControl from "./MockHeadersController";

const RegisterMock = () => {
  // {
  //   "api_id": 0,
  //   "mock_version": 0,
  //   "mock_http_status": 0,
  //   "description": "string",
  //   "mock_json_body": {}
  // }
  let navigate = useNavigate();
  const { apiId, mock_version } = useParams();
  const [mockVersion, setMockVersion] = useState<any>();
  const [mockHttpStatus, setMockHttpStatus] = useState<any>(200);
  const [description, setDescription] = useState("");
  const [mockJsonHeaders, setMockJsonHeaders] = useState([
    // { name: "", value: "" },
  ]);
  const [mockJsonBody, setMockJsonBody] = useState<any>([]);
  const [responseStatus, setResponseStatus] = useState<any>(null);

  const registerNewAPICaller = useCallback(
    async (data: any) => {
      try {
        const response = await registerNewMock(data);
        if (response.status >= 200 && response.status < 300) {
          setResponseStatus({
            success: true,
            message: "Mock Added successfully",
          });
          setTimeout(() => navigate(`/admin/view/${apiId}`), 5000);
        } else {
          throw new Error("Something went wrong");
        }
      } catch (e: any) {
        setResponseStatus({
          success: false,
          message: e.message || "Something went wrong",
        });
      } finally {
        setTimeout(() => setResponseStatus(null), 5000);
      }
    },
    [navigate, apiId, mock_version]
  );

  const handleJSONBodyValidation = (text: string) => {
    try {
      const c = JSON.parse(text);
      setMockJsonBody(c);
    } catch (e) {
      // console.log("Invalid JSON", e.message);
      setMockJsonBody(null);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const [res] = await promiseHandler(() => {
      return registerNewAPICaller({
        api_id: apiId,
        mock_version: mockVersion,
        mock_http_status: parseInt(mockHttpStatus as string),
        description,
        mock_json_body: mockJsonBody,
        mock_json_headers: mockJsonHeaders.reduce((v, c) => {
          const x = { ...v };
          // @ts-ignore
          x[c.name] = c.value;
          return x;
        }, {}),
      });
    });
  };

  return (
    <>
      <Header title="Register New Mock For API" bigHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Enter the values below</h3>
                  </Col>
                  {/* <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Help / Documentation
                    </Button>
                  </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    API Mock Information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="api-name"
                          >
                            Mock Version
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="api-name"
                            placeholder="Mock Version"
                            type="text"
                            onChange={(e) => setMockVersion(e.target.value)}
                            value={mockVersion}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="http-method"
                          >
                            Mock HTTP Status
                          </label>
                          <Input
                            id="http-method"
                            type="select"
                            className="form-control-alternative"
                            placeholder="GET"
                            onChange={(e) => setMockHttpStatus(e.target.value)}
                          >
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="404">404</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="description"
                          >
                            Mock Description
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="description"
                            placeholder="Write a user understandable description for this API"
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Mock JSON Header
                  </h6>
                  <MockHeadersControl
                    mockJsonHeaders={mockJsonHeaders}
                    setMockJsonHeaders={setMockJsonHeaders}
                  />
                  <h6 className="heading-small text-muted mb-4">
                    Mock JSON Body
                  </h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label>
                        Write down the parameters in a valid JSON format
                      </label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Enter the JSON value without double quotes"
                        rows="4"
                        type="textarea"
                        onChange={(e) =>
                          handleJSONBodyValidation(e.target.value)
                        }
                      />
                    </FormGroup>

                    {mockJsonBody !== null ? (
                      <Alert color="success">
                        <strong>Success!</strong> You are good at writing JSON
                        😁
                      </Alert>
                    ) : (
                      <Alert color="danger">
                        <strong>Attention!</strong> above text is not a valid
                        JSON 😓
                      </Alert>
                    )}
                  </div>
                  {responseStatus && (
                    <div className="pl-lg-4">
                      <Alert
                        color={responseStatus.success ? "success" : "danger"}
                      >
                        {responseStatus.message}
                      </Alert>
                    </div>
                  )}
                </Form>
              </CardBody>
            </Card>
            <div className="pt-4">
              <Button onClick={handleSubmit}>Create Mock</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RegisterMock;
