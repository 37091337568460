import { Container } from "reactstrap";

const Header = ({ title = null, bigHeader = false }: IHeader) => {
  return (
    <>
      <div
        className={`header bg-gradient-info pb-8 pt-5 ${
          bigHeader ? "pt-md-8" : "pt-md-4"
        }`}
      >
        <Container fluid>
          <div className="header-body">
            {title && <h2 className="display-3 text-secondary">{title}</h2>}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
