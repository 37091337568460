export const handleError = (error: any) => {
  let errorMsg = error;
  if (error && error.data) {
    errorMsg = error.data.error;
  }
  return errorMsg;
};

export const promiseHandler = async (promise: any) => {
  try {
    const response = await promise();
    return [response.data, null];
  } catch (error: any) {
    const errorMsg = handleError(error.response);
    return [null, errorMsg];
  }
};
