interface ISelectProject {
  id: string;
  name: string;
}

const SelectProject = ({ id, name }: ISelectProject, navigate: any) => {
  window.localStorage.setItem("activeProject", id);
  window.localStorage.setItem("activeProjectName", name);
  navigate("/admin/index");
};

export { SelectProject };