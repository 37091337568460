import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { NAME_VALIDATION_REGEX } from "utils";
import ParametersFormControl from "views/APIs/ParametersController";

const ApiForm = ({
  name,
  setName,
  method,
  setMethod,
  type,
  setType,
  operationName,
  setOperationName,
  description,
  setDescription,
  route_url,
  setRoute_url,
  parameters,
  setParameters,
  handleSubmit,
  ctaText,
}: any) => {
  return (
    <Container className="mt--7" fluid>
      <Row>
        <Col className="order-xl-1" xl="8">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Enter the values below</h3>
                </Col>
                {/* <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Help / Documentation
                    </Button>
                  </Col> */}
              </Row>
            </CardHeader>
            <CardBody>
              <Form>
                <h6 className="heading-small text-muted mb-4">
                  API Information
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="request-type"
                        >
                          Request Type
                        </label>
                        <Input
                          id="request-type"
                          type="select"
                          className="form-control-alternative"
                          placeholder="REST"
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value="rest">REST</option>
                          <option value="graphql">GRAPHQL</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={`6 ${type === "graphql" ? "pr-0" : ""}`}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="api-name"
                        >
                          API Name
                        </label>
                        <Input
                          className={`form-control-alternative ${
                            !NAME_VALIDATION_REGEX.test(name) && "is-invalid "
                          }`}
                          id="api-name"
                          placeholder="My API Name"
                          type="text"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          invalid={!NAME_VALIDATION_REGEX.test(name)}
                        />
                        <div className="text-xs">
                          Note:
                          <ul>
                            <li> This field does not accept spaces </li>
                            <li>
                              Please dont start with `/` because it will be
                              added by mock engine
                            </li>
                          </ul>
                        </div>
                      </FormGroup>
                    </Col>
                    {type === "graphql" && (
                      <>
                        <Col lg="1 pt-5 text-center px-0 pl-0">/</Col>
                        <Col lg="5">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="api-name"
                            >
                              GraphQL OperationName
                            </label>
                            <Input
                              className={`form-control-alternative ${
                                !NAME_VALIDATION_REGEX.test(operationName) &&
                                "is-invalid "
                              }`}
                              id="api-name"
                              placeholder="GraphQL Operation Name"
                              type="text"
                              onChange={(e) => setOperationName(e.target.value)}
                              value={operationName}
                              invalid={
                                !NAME_VALIDATION_REGEX.test(operationName)
                              }
                            />
                            <div className="text-xs">
                              Note:
                              <ul>
                                <li> This field does not accept spaces </li>
                                <li>
                                  Please dont start with `/` because it will be
                                  added by mock engine
                                </li>
                              </ul>
                            </div>
                          </FormGroup>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row>
                    <Col lb="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="http-method"
                        ></label>
                        <Input
                          id="http-method"
                          type="select"
                          className="form-control-alternative"
                          placeholder="GET"
                          value={method}
                          onChange={(e) => setMethod(e.target.value)}
                        >
                          <option value="GET">GET</option>
                          <option value="POST">POST</option>
                          <option value="PUT">PUT</option>
                          <option value="DELETE">DELETE</option>
                          <option value="PATCH">PATCH</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="route=path"
                        >
                          Route Path
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="route=path"
                          placeholder="/"
                          type="text"
                          value={route_url}
                          onChange={(e) => setRoute_url(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="description"
                        >
                          Description
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="description"
                          placeholder="Write a user understandable description for this API"
                          type="text"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">Parameters</h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col>
                      <ParametersFormControl
                        parameters={parameters}
                        setParameters={setParameters}
                      />
                    </Col>
                  </Row>
                </div>
              </Form>
            </CardBody>
          </Card>
          <div className="pt-4">
            <Button
              onClick={handleSubmit}
              disabled={!NAME_VALIDATION_REGEX.test(name)}
            >
              {ctaText}
              Create API
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ApiForm;
