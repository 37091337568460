import AdminFooter from "components/Footers/AdminFooter";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar";
import { LoginContext } from "context/authContext";
import React, { useContext, useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Container } from "reactstrap";

import routes from "../routes/routes";

const Admin = (props: any) => {
  const mainContent = React.useRef(null);
  const authContext = useContext(LoginContext);
  let navigate = useNavigate();
  const location = useLocation();
  // React.useEffect(() => {
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  //   mainContent.current.scrollTop = 0;
  // }, [location]);

  useEffect(() => {
    if (!authContext.loggedIn) {
      navigate("/auth/login");
    }
  }, [authContext.loggedIn, navigate]);

  if (!authContext.loggedIn) {
    return <Navigate to="/auth/login" replace />;
  }

  const getRoutes = (routes: Iroute[]) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          // @ts-ignore
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return `${
          localStorage.getItem("activeProjectName") || "Default Project"
        } - ${routes[i].name}`;
      }
    }
    return localStorage.getItem("activeProjectName") || "Default Project";
  };

  return authContext.loggedIn ? (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/startappz-logo.jpg").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          // @ts-ignore
          brandText={getBrandText(props?.location?.pathname)}
        />
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/admin/index" replace />} />
        </Routes>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  ) : (
    <Navigate to="/auth/login" replace />
  );
};

export default Admin;
