import { promiseHandler } from "modules/formatters";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Modal,
  Row,
  Table,
} from "reactstrap";
// core components
import Header from "components/Headers/Header";
import FloatingButton from "components/custom/floating-button/floating-button";
import FloatingBubble from "components/custom/floatingBubble/floatingBubble";
import { deleteAPI, getAllRegisteredAPIs } from "services/http-service";

const ListAllAPIs = () => {
  const [gatewaysList, setGatewaysList] = useState<null | Igateway[]>(null);
  const [toBeDeletedAPI, setToBeDeletedAPI] = useState<null | ItoBeDeleted>(
    null
  );
  const [currentProject, setCurrentProject] = useState<number | string | null>(
    "1"
  );

  useEffect(() => {
    const id = localStorage.getItem("activeProject");
    // Backend is returning null as string, so please ignore
    setCurrentProject(id == "null" ? null : id);
  }, []);
  const getData = useCallback(() => {
    async function wrapper() {
      const response = await getAllRegisteredAPIs();
      response.data = response.data.map((r: any) => {
        r.workspace_id = r.workspace_id === null ? 1 : r.workspace_id;
        return r;
      });
      // console.log(response.data);
      setGatewaysList(response.data);
      return response;
    }
    wrapper();
  }, []);

  const handleDelete = useCallback(
    async (id: string) => {
      try {
        const response = await deleteAPI(id);

        if (response.status >= 200 && response.status < 300) {
          // setResponseStatus({
          //   success: true,
          //   message: "API Added successfully",
          // });
        } else {
          throw new Error("Something went wrong");
        }
      } catch (e) {
      } finally {
        setToBeDeletedAPI(null);
        getData();
      }
    },
    [getData]
  );

  useEffect(() => {
    const asyncCaller = async () => {
      await promiseHandler(() => getData());
    };
    asyncCaller();
  }, [getData]);
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Dark table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <h3 className="text-white mb-0">All APIs</h3>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col"> ID </th>
                    <th scope="col" />
                    <th scope="col">Name</th>
                    <th scope="col">Mocks Count</th>
                    <th scope="col">HTTP Method</th>
                    <th scope="col">API Type</th>
                    <th scope="col">Route Path</th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {gatewaysList &&
                    gatewaysList.map(
                      ({
                        id,
                        name,
                        description,
                        mocks_count,
                        method,
                        type,
                        route_url,
                        workspace_id,
                      }) =>
                        workspace_id == currentProject ? (
                          <tr key={id}>
                            <th scope="row">{id}</th>
                            <td>
                              <Link to={`/admin/view/${id}`} relative="path">
                                <Button color="success" size="sm" type="button">
                                  View
                                </Button>
                              </Link>
                              <Link
                                to={`/admin/modify/${id}`}
                                className="btn btn-sm"
                              >
                                <Button color="primary" size="sm" type="button">
                                  Edit
                                </Button>
                              </Link>
                              <Button
                                color="danger"
                                type="button"
                                size="sm"
                                onClick={() => {
                                  setToBeDeletedAPI({ id, name, description });
                                }}
                              >
                                Delete
                              </Button>
                            </td>
                            <td> {name} </td>
                            <td> {mocks_count} </td>
                            <td>{method}</td>
                            <td>{type}</td>
                            <td>{route_url}</td>
                            <td>{description}</td>
                          </tr>
                        ) : null
                    )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      <FloatingButton
        link="/admin/register"
        text="+"
        title="Register New API"
      />
      <FloatingBubble />
      <Modal
        className="modal-dialog-centered modal-danger"
        contentClassName="bg-gradient-danger"
        isOpen={toBeDeletedAPI !== null}
        toggle={() => setToBeDeletedAPI(null)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            Your attention is required
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setToBeDeletedAPI(null)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="ni ni-bell-55 ni-3x" />
            <h4 className="heading mt-4">You should read this!</h4>
            <p>You are about to delete this API. Are you sure?</p>
            {toBeDeletedAPI && (
              <code>
                ID: {toBeDeletedAPI.id} <br />
                Name: {toBeDeletedAPI.name} <br />
                Description: {toBeDeletedAPI.description} <br />
              </code>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <Button
            className="btn-white"
            color="default"
            type="button"
            // @ts-ignore
            onClick={() => handleDelete(toBeDeletedAPI.id)}
          >
            Yes, Delete it
          </Button>
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setToBeDeletedAPI(null)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ListAllAPIs;
