import Header from "components/Headers/Header";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { getMockByAPIId } from "services/http-service";
import MockHeadersControl from "./MockHeadersController";
import { useParams } from "react-router-dom";

const ViewMock = () => {
  const [mockHttpStatus, setMockHttpStatus] = useState(200);
  const [description, setDescription] = useState("");
  const [mockJsonHeaders, setMockJsonHeaders] = useState([
    { name: "", value: "" },
  ]);
  const [mockJsonBody, setMockJsonBody] = useState([]);
  const [responseStatus, setResponseStatus] = useState<any>(null);
  const { apiId, mock_version } = useParams();
  const parametersRef = useRef("");

  useEffect(() => {
    const asyncCaller = async () => {
      const mockResponse = await getMockByAPIId(apiId as string);
      const mocks = mockResponse.data.availabile_mocks;
      const {
        description,
        mock_http_status,
        mock_json_headers,
        mock_json_body,
      } = mocks.find((mock: any) => mock.mock_version == mock_version);

      const modifiedMockHeaders: any = [];

      mock_json_headers &&
        Object.keys(mock_json_headers).forEach((key) => {
          modifiedMockHeaders.push({
            name: key,
            value: mock_json_headers[key],
          });
        });

      setMockHttpStatus(mock_http_status);
      setDescription(description);
      setMockJsonHeaders(modifiedMockHeaders);
      setMockJsonBody(mock_json_body);
      // @ts-ignore
      parametersRef.current.value = JSON.stringify(mock_json_body);
    };
    asyncCaller();
  }, []);

  const handleParameterValidation = (text: string) => {
    try {
      const c = JSON.parse(text);
      setMockJsonBody(c);
    } catch (e) {
      // console.log("Invalid JSON", e.message)
      // @ts-ignore
      setMockJsonBody(null);
    }
  };

  return (
    <>
      <Header title={`View - MOCK: ${mock_version} of API ${apiId}`} />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Mock Details</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    API Mock Information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="api-name"
                          >
                            Mock Version
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="api-name"
                            placeholder="Mock Version"
                            type="text"
                            disabled
                            // onChange={(e) => setMockVersion(e.target.value)}
                            value={mock_version}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="http-method"
                          >
                            Mock HTTP Status
                          </label>
                          <Input
                            id="http-method"
                            type="select"
                            className="form-control-alternative"
                            disabled
                            // @ts-ignore
                            onChange={(e) => setMockHttpStatus(e.target.value)}
                          >
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="404">404</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="description"
                          >
                            Mock Description
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="description"
                            placeholder="Write a user understandable description for this API"
                            type="text"
                            disabled
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Mock JSON Header
                  </h6>
                  <MockHeadersControl
                    mockJsonHeaders={mockJsonHeaders}
                    setMockJsonHeaders={setMockJsonHeaders}
                    viewMode
                  />
                  <h6 className="heading-small text-muted mb-4">
                    Mock JSON Body
                  </h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label>Parameters</label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Enter the JSON value without double quotes"
                        rows="4"
                        type="textarea"
                        disabled
                        // @ts-ignore
                        innerRef={parametersRef}
                        onChange={(e) =>
                          handleParameterValidation(e.target.value)
                        }
                      />
                    </FormGroup>
                  </div>
                  {responseStatus && (
                    <div className="pl-lg-4">
                      <Alert
                        // @ts-ignore
                        color={responseStatus.success ? "success" : "danger"}
                      >
                        {responseStatus.message}
                      </Alert>
                    </div>
                  )}
                </Form>
              </CardBody>
            </Card>
            <div className="pt-4">
              <Link to={`/admin/mock/${apiId}/modify/${mock_version}`}>
                <Button>Edit</Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ViewMock;
