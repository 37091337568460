import ListAllAPIs from "views/APIs/ListAllAPIs";
import ModifyAPI from "views/APIs/ModifyAPI";
import RegisterAPI from "views/APIs/RegisterAPI";
import ViewAPI from "views/APIs/ViewAPI";

let apiRoutes: Iroute[] = [
  {
    path: "/index",
    name: "APIs",
    // name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <ListAllAPIs />,
    layout: "/admin",
    sidebar: true,
  },
  {
    path: "/register",
    name: "Register API",
    icon: "ni ni-tv-2 text-primary",
    component: <RegisterAPI />,
    layout: "/admin",
  },
  {
    path: "/modify/:id",
    name: "Modify API",
    icon: "ni ni-tv-2 text-primary",
    component: <ModifyAPI />,
    layout: "/admin",
  },
  {
    path: "/view/:id",
    name: "View API",
    icon: "ni ni-tv-2 text-primary",
    component: <ViewAPI />,
    layout: "/admin",
  },
];
export default apiRoutes;
