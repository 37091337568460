// reactstrap components
import { Col, Nav, NavItem, NavLink, Row } from "reactstrap";

const Footer = () => {
  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          <div className="copyright text-center text-xl-left text-muted">
            {new Date().getFullYear()}{" "}
            <a
              className="font-weight-bold ml-1"
              href="https://www.startappz.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              {`StartAppz - Build: #${
                process.env.REACT_APP_BUILD_NUMBER || "N/A"
              }`}
            </a>
          </div>
        </Col>

        <Col xl="6">
          <Nav className="nav-footer justify-content-center justify-content-xl-end">
            <NavItem>
              <NavLink
                href="https://startappz.atlassian.net/wiki/spaces/SKEL/pages/819724289/App+APIs+Gateway"
                rel="noopener noreferrer"
                target="_blank"
              >
                Confluence Page
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
