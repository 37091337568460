import Header from "components/Headers/Header";
import Loading from "components/Loading/Loading";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
import { getMockByAPIId, getOneRegisteredAPI } from "services/http-service";
import ListAPIMocks from "views/Mocks/ListAPIMocks";
import ParametersFormControl from "./ParametersController";

const ViewAPI = () => {
  let navigate = useNavigate();
  const [apiDetails, setAPIDetails] = useState<IapiDetails | null>(null);
  const [name, setName] = useState("");
  const [operationName, setOperationName] = useState("");
  const [mocks, setMocks] = useState<Imock[]>([]);
  const { id } = useParams();
  useEffect(() => {
    const asyncCaller = async () => {
      const response = await getOneRegisteredAPI(id as string);
      const mockResponse = await getMockByAPIId(id as string);
      setAPIDetails(response.data[0]);
      let finalName = response.data[0].name;
      let operationName = "";
      if (response.data[0].type === "graphql") {
        const split = finalName.split("/");
        operationName = split.pop();
        finalName = split.join("/");
      }
      setName(finalName);
      setOperationName(operationName);
      setMocks(mockResponse.data.availabile_mocks);
    };
    asyncCaller();
  }, [id]);
  if (apiDetails === null) return <Loading />;
  return (
    <>
      <Header title={`API: ${apiDetails?.name}`} bigHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="5">
                    <h3 className="mb-0">API Details</h3>
                  </Col>
                  <Col className="text-right" xs="7">
                    <Button
                      color="primary"
                      onClick={() => navigate(-1)}
                      size="sm"
                    >
                      Back
                    </Button>
                    <Link to={`/admin/modify/${id}`}>
                      <Button color="warning" size="sm">
                        Edit
                      </Button>
                    </Link>
                    {/* <Button
                      color="danger"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Delete (dummy)
                    </Button>
                    <Button
                      color="primary"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Help / Documentation (Dummy)
                    </Button> */}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    API Information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="api-name"
                          >
                            Date Created
                          </label>
                          <p>{apiDetails.date_created}</p>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="http-method"
                          >
                            API Status
                          </label>
                          <p>
                            <Badge color="" className="badge-dot mr-4">
                              <i className="bg-success" />
                              Active (This is dummy)
                            </Badge>
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="api-name"
                          >
                            Request Type
                          </label>
                          <p>{apiDetails.type}</p>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="api-name"
                          >
                            API Name
                          </label>
                          <p>{name}</p>
                        </FormGroup>
                      </Col>
                      {apiDetails.type === "graphql" && (
                        <>
                          <Col lg="1 pt-5 text-center px-0 pl-0">/</Col>
                          <Col lg="5">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="api-name"
                              >
                                GraphQL OperationName
                              </label>
                              <p>{operationName}</p>
                              {/* <Input
                                className="form-control-alternative"
                                id="api-name"
                                placeholder="My API Name"
                                type="text"
                                onChange={(e) =>
                                  setOperationName(e.target.value)
                                }
                                value={operationName}
                              /> */}
                            </FormGroup>
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="http-method"
                          >
                            HTTP Method
                          </label>
                          <p>{apiDetails.method}</p>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="route=path"
                          >
                            Route Path
                          </label>
                          <p>{apiDetails.route_url}</p>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="description"
                          >
                            Description
                          </label>
                          <p>{apiDetails.description}</p>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Parameters</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col>
                        <ParametersFormControl
                          parameters={apiDetails.parameters}
                          viewMode
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Button onClick={() => navigate(-1)}>Back</Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <div className="pt-2">
              <br />
              <Link to={`/admin/mock/${id}/register`}>
                <Button color="primary" type="button">
                  Add Mock
                </Button>
              </Link>
            </div>
            <br />
          </Col>
        </Row>
      </Container>
      <ListAPIMocks mocks={mocks} apiId={id as string} />
    </>
  );
};

export default ViewAPI;
