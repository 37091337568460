import ProjectIndex from "views/ProjectSelector/ProjectIndex";
import SelectProjectPage from "views/ProjectSelector/SelectProjectPage";
import Login from "views/examples/Login";

import ApiRoutes from "./ApiRoutes";
import MockRoutes from "./MockRoutes";

var routes: Iroute[] = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
  ...ApiRoutes,
  ...MockRoutes,
  {
    path: "/select-project",
    name: "Projects Select",
    icon: "ni ni-tv-2 text-primary",
    component: <SelectProjectPage />,
    layout: "/admin",
  },
  {
    path: "/projects",
    name: "Projects",
    icon: "ni ni-tv-2 text-primary",
    component: <ProjectIndex />,
    layout: "/admin",
    sidebar: true,
  },
];
export default routes;
