import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Modal,
  Row,
  Table,
} from "reactstrap";
import { deleteMock } from "services/http-service";

const ListAPIMocks = ({ mocks, apiId }: IListAPIMocks) => {
  const [toBeDeletedAPI, setToBeDeletedAPI] = useState<null | ItoBeDeleted>(
    null
  );

  const handleDelete = useCallback(
    async (p: any) => {
      const response = await deleteMock(apiId, p.x);
      if (response.status >= 200 && response.status < 300) {
        // setResponseStatus({
        //   success: true,
        //   message: "API Deleted successfully",
        // });
        // setTimeout(() => history.push(`/admin/view/${apiId}`), 3000);
      } else {
        throw new Error("Something went wrong");
      }
      // } catch (e) {
      // } finally {
      setToBeDeletedAPI(null);
      // }
    },
    [apiId]
  );

  return (
    <>
      <Container className="mt-7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">MOCKS Related to this API</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col"> Version </th>
                    <th scope="col" />
                    <th scope="col"> HTTP Status </th>
                    <th scope="col"> Description </th>
                    {/* <th scope="col"> JSON Header </th> */}
                    <th scope="col"> JSON Body </th>
                  </tr>
                </thead>
                <tbody>
                  {mocks &&
                    mocks.map((mock: Imock) => (
                      <tr key={mock.mock_version}>
                        <td>{mock.mock_version}</td>
                        <td>
                          <Link
                            to={`/admin/mock/${apiId}/view/${mock.mock_version}`}
                          >
                            <Button color="success" size="sm" type="button">
                              View
                            </Button>
                          </Link>
                          <Link
                            to={`/admin/mock/${apiId}/modify/${mock.mock_version}`}
                          >
                            <Button color="primary" size="sm" type="button">
                              Edit
                            </Button>
                          </Link>
                          <Button
                            className="btn-icon btn-3"
                            color="danger"
                            type="button"
                            size="sm"
                            onClick={() => {
                              setToBeDeletedAPI({
                                id: mock.mock_version,
                                description: mock.description,
                              });
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                        <td>{mock.mock_http_status}</td>
                        <td>{mock.description}</td>
                        {/* <td>{JSON.stringify(mock.mock_json_headersa)}</td> */}
                        <td>{JSON.stringify(mock.mock_json_body)}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* <FloatingButton
        link={`/admin/mock/${apiId}/register`}
        text="Add New Mock"
        title="Add New Mock"
        version="v2"
      /> */}

      <Modal
        className="modal-dialog-centered modal-danger"
        contentClassName="bg-gradient-danger"
        isOpen={toBeDeletedAPI !== null}
        toggle={() => setToBeDeletedAPI(null)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            Your attention is required
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setToBeDeletedAPI(null)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="ni ni-bell-55 ni-3x" />
            <h4 className="heading mt-4">You should read this!</h4>
            <p>You are about to delete this Mock. Are you sure?</p>
            {toBeDeletedAPI && (
              <code>
                Mock Version: {toBeDeletedAPI.id} <br />
                Description: {toBeDeletedAPI.description} <br />
              </code>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <Button
            className="btn-white"
            color="default"
            type="button"
            // @ts-ignore
            onClick={() => handleDelete({ x: toBeDeletedAPI.id })}
          >
            Yes, Delete it
          </Button>
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setToBeDeletedAPI(null)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ListAPIMocks;
