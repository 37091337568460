import Styles from "./floatingBubble.module.scss";
import { Link } from "react-router-dom";

const FloatingBubble = ({ text, link, title = "" }: IFloatingBubble) => {
  return (
    <div className={`${Styles.floatingBubble} bg-gradient-green`}>
      You are using Project:{" "}
      {localStorage.getItem("activeProjectName") || "default"}. Please click{" "}
      <Link to="/admin/projects">here</Link> to change the project
    </div>
  );
};

export default FloatingBubble;
