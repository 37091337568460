import { Col, FormGroup, Button, Input, Row, Badge } from "reactstrap";
import { cloneDeep } from "lodash";

const MockHeadersControl = ({
  mockJsonHeaders,
  setMockJsonHeaders,
  viewMode = false,
}: IMockHeadersControl) => {
  const BASE_HEADER = {
    name: "",
    value: "",
  };

  const setParameterValues = (value: any, index: number, objectKey: string) => {
    setMockJsonHeaders((params: any) => {
      const newParams = cloneDeep(params);
      newParams[index][objectKey] = value;
      return newParams;
    });
  };

  const addMoreHeader = () => {
    setMockJsonHeaders((params: any) => {
      const newParams = cloneDeep(params);
      newParams.push(BASE_HEADER);
      return newParams;
    });
  };

  const removeHeader = (index: number) => {
    setMockJsonHeaders((params: any) => {
      const newParams = cloneDeep(params);
      newParams.splice(index, 1);
      return newParams;
    });
  };
  return (
    <>
      <Badge color="danger mb-4">
        Total Mock Headers: {mockJsonHeaders?.length || 0}
      </Badge>
      {mockJsonHeaders.map((parameter: any, index: number) => (
        <div key={index}>
          <Row>
            <Col>
              <Badge className="badge-default">
                Header Number: {index + 1}
              </Badge>
            </Col>
            <Col className="text-right">
              {!viewMode && mockJsonHeaders.length > 1 && (
                <Button
                  size="sm"
                  color="danger"
                  onClick={() => removeHeader(index)}
                >
                  Remove this Header
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor={`param-name-${index}`}
                >
                  Mock Header Name
                </label>
                <Input
                  className="form-control-alternative"
                  id={`param-name-${index}`}
                  placeholder="Header Name"
                  type="text"
                  value={parameter.name}
                  readOnly={viewMode}
                  onChange={(e) =>
                    setParameterValues(e.target.value, index, "name")
                  }
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor={`param-type-${index}`}
                >
                  Mock Header Value
                </label>
                <Input
                  className="form-control-alternative"
                  id={`param-type-${index}`}
                  placeholder="Header Value"
                  type="text"
                  readOnly={viewMode}
                  value={parameter.value}
                  onChange={(e) =>
                    setParameterValues(e.target.value, index, "value")
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      ))}
      <br />
      {!viewMode && (
        <Row className="mt-4">
          <Col lg="6">
            <FormGroup>
              <Button
                className="form-control-alternative"
                onClick={addMoreHeader}
                color="success"
              >
                Add One More Mock Header
              </Button>
            </FormGroup>
          </Col>
          <Col lg="6"></Col>
        </Row>
      )}
    </>
  );
};

export default MockHeadersControl;
