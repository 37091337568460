import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASE_URL;

const HTTP = axios.create({
  baseURL: `${baseUrl}/`,
  headers: {
    charset: "utf-8",
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

HTTP.defaults.headers.common.Accept = "application/json";

HTTP.interceptors.request.use((config) => {
  config.headers["Authorization"] = `Bearer ${Cookies.get("ApiGWAuthToken")}`;
  return config;
});

export const getOneRegisteredAPI = (id: string) => {
  const config = {
    method: "get",
    url: `/manage/registered_apis/get_api_by_id/${id}`,
  };
  return HTTP(config);
};

export const patchOneRegisteredAPI = (id: string, data: any) => {
  const config = {
    method: "put",
    url: `/manage/registered_apis/modify_api_by_id/${id}`,
    data: JSON.stringify(data),
  };
  return HTTP(config);
};

export const getAllRegisteredAPIs = () => {
  const config = {
    method: "get",
    url: `/manage/registered_apis/all`,
  };
  return HTTP(config);
};

export const registerNewAPI = (data: any) => {
  const config = {
    method: "post",
    url: `/manage/registered_apis/add`,
    data: JSON.stringify(data),
  };
  return HTTP(config);
};

export const deleteAPI = (id: string) => {
  const config = {
    method: "delete",
    url: `/manage/registered_apis/remove_api_by_id/${id}`,
  };
  return HTTP(config);
};

// MOCK

export const registerNewMock = (data: any) => {
  const config = {
    method: "post",
    url: `/api_mocks/associate_to_api`,
    data: JSON.stringify(data),
  };
  return HTTP(config);
};

export const getMockByAPIId = (api_id: string) => {
  const config = {
    method: "get",
    url: `/manage/api_mocks/get_mocks_by_api_id/${api_id}`,
  };
  return HTTP(config);
};

export const patchOneRegisteredMock = (
  api_id: string,
  mock_version: string,
  data: any
) => {
  const config = {
    method: "put",
    url: `/api_mocks/modify_mock_version/${api_id}/${mock_version}`,
    data: JSON.stringify(data),
  };
  return HTTP(config);
};

export const deleteMock = (api_id: string, mock_version: string) => {
  const config = {
    method: "delete",
    url: `/api_mocks/remove_mock_version/${api_id}/${mock_version}`,
  };
  return HTTP(config);
};

// Project Services

export const getAllRegisteredProjects = () => {
  const config = {
    method: "get",
    url: `/manage/registered_workspaces/all`,
  };
  return HTTP(config);
};

export const getOneRegisteredProject = (project_id: string) => {
  const config = {
    method: "get",
    url: `/manage/registered_workspaces/get_workspace_by_id/${project_id}`,
  };
  return HTTP(config);
};

export const registerNewProject = (data: any) => {
  const config = {
    method: "post",
    url: `/manage/registered_workspaces/add`,
    data: JSON.stringify(data),
  };
  return HTTP(config);
};

export const editRegisteredProject = (project_id: string, data: any) => {
  const config = {
    method: "put",
    url: `/manage/registered_workspaces/modify_workspace_by_id/${project_id}`,
    data: JSON.stringify(data),
  };
  return HTTP(config);
};

// Auth Services

export const logUserOut = function () {
  const config = {
    method: "get",
    url: `/auth/logout`,
    "Content-Type": "application/x-www-form-urlencoded",
  };
  return HTTP(config);
};

export default HTTP;
