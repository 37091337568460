import { Col, FormGroup, Button, Input, Row, Badge } from "reactstrap";
import { cloneDeep } from "lodash";

const ParametersFormControl = ({
  parameters,
  setParameters,
  viewMode = false,
}: IParametersFormControl) => {
  const BASE_PARAM = {
    name: "",
    type: "",
    data_type: "",
    is_optional: true,
    note: "",
  };

  // SAMPLE MAP
  /* [
        {
          "name": "versiond1",
          "type": "request_body",
          "data_type": "json",
          "is_optional": true,
          "note": "if not specify it will be v1"
        }
      ] */

  const setParameterValues = (value: any, index: number, objectKey: string) => {
    setParameters((params: any) => {
      const newParams = cloneDeep(params);
      newParams[index][objectKey] = value;
      return newParams;
    });
  };

  const addMoreParameter = () => {
    setParameters((params: Iparameter[]) => {
      const newParams = cloneDeep(params);
      newParams.push(BASE_PARAM);
      return newParams;
    });
  };

  const removeParameter = (index: number) => {
    setParameters((params: Iparameter[]) => {
      const newParams = cloneDeep(params);
      newParams.splice(index, 1);
      return newParams;
    });
  };
  return (
    <>
      <Badge color="danger">Total Parameters: {parameters?.length || 0}</Badge>
      {parameters.map((parameter, index) => (
        <div key={index} className="mt-4">
          <Row>
            <Col>
              <Badge className="badge-default">
                Parameter Number: {index + 1}
              </Badge>
            </Col>
            <Col className="text-right">
              {!viewMode && parameters.length > 1 && (
                <Button
                  size="sm"
                  color="danger"
                  onClick={() => removeParameter(index)}
                >
                  Remove this Parameter
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor={`param-name-${index}`}
                >
                  Name
                </label>
                <Input
                  className="form-control-alternative"
                  id={`param-name-${index}`}
                  placeholder="Parameter Name"
                  type="text"
                  value={parameter.name}
                  readOnly={viewMode}
                  onChange={(e) =>
                    setParameterValues(e.target.value, index, "name")
                  }
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor={`param-type-${index}`}
                >
                  Type
                </label>
                <Input
                  className="form-control-alternative"
                  id={`param-type-${index}`}
                  placeholder="ex: request_body"
                  type="text"
                  readOnly={viewMode}
                  value={parameter.type}
                  onChange={(e) =>
                    setParameterValues(e.target.value, index, "type")
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor={`type-${index}`}>
                  Data Type
                </label>
                <Input
                  id={`type-${index}`}
                  type="select"
                  className="form-control-alternative"
                  placeholder="GET"
                  readOnly={viewMode}
                  disabled={viewMode}
                  onChange={(e) =>
                    setParameterValues(e.target.value, index, "data_type")
                  }
                >
                  <option value="application/json">application/json</option>
                  <option value="application/xml">application/xml</option>
                  <option value="text/xml">text/xml</option>
                  <option value="text/json">text/json</option>
                  <option value="text/pain">text/pain</option>
                </Input>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor={`param-is-optional-${index}`}
                >
                  Is Optional
                </label>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id={`param-is-optional-${index}`}
                    type="checkbox"
                    checked={parameter.is_optional}
                    disabled={viewMode}
                    onChange={(e) => {
                      // console.log(e.target.checked);
                      setParameterValues(
                        e.target.checked,
                        index,
                        "is_optional"
                      );
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`param-is-optional-${index}`}
                  ></label>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor={`param-note-${index}`}
                >
                  Note
                </label>
                <Input
                  className="form-control-alternative"
                  id={`param-note-${index}`}
                  placeholder=""
                  type="text"
                  readOnly={viewMode}
                  value={parameter.note}
                  onChange={(e) =>
                    setParameterValues(e.target.value, index, "note")
                  }
                />
              </FormGroup>
            </Col>
            <Col lg="6"></Col>
          </Row>
        </div>
      ))}
      <br />
      {!viewMode && (
        <Row className="mt-4">
          <Col lg="6">
            <FormGroup>
              <Button
                className="form-control-alternative"
                onClick={addMoreParameter}
              >
                Add One More Parameter
              </Button>
            </FormGroup>
          </Col>
          <Col lg="6"></Col>
        </Row>
      )}
    </>
  );
};

export default ParametersFormControl;
