import ReactDOM from "react-dom/client";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/css/custom.css";
import "assets/plugins/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-react.scss";

import LoginProvider from "./context/authContext";
import AdminLayout from "./layouts/Admin";
import AuthLayout from "./layouts/Auth";

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <LoginProvider>
    <HashRouter>
      <Routes>
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="/" element={<Navigate to="/admin/index" replace />} />
        <Route path="*" element={<Navigate to="/admin/index" replace />} />
      </Routes>
    </HashRouter>
  </LoginProvider>
);
