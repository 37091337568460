import Header from "components/Headers/Header";
import ApiForm from "components/forms/ApiForm";
import { promiseHandler } from "modules/formatters";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert
} from "reactstrap";
import { registerNewAPI } from "services/http-service";
import { NAME_VALIDATION_REGEX } from "utils";

const RegisterAPI = () => {
  const [name, setName] = useState("");
  const [method, setMethod] = useState("GET");
  const [type, setType] = useState("rest");
  const [operationName, setOperationName] = useState("");
  const [description, setDescription] = useState("");
  const [route_url, setRoute_url] = useState("");
  const [responseStatus, setResponseStatus] = useState<any>(null);
  const navigate = useNavigate();

  // Parameter Vars
  const [parameters, setParameters] = useState([]);

  const registerNewAPICaller = useCallback(
    async (data: any) => {
      try {
        if (
          NAME_VALIDATION_REGEX.test(data.name) &&
          (type === "graphql"
            ? NAME_VALIDATION_REGEX.test(data.operationName)
            : true)
        ) {
          const response = await registerNewAPI(data);

          if (response.status >= 200 && response.status < 300) {
            setResponseStatus({
              // @ts-ignore
              success: true,
              message: "API Added successfully",
            });
            if (response.data?.id)
              navigate(`/admin/mock/${response.data.id}/register`);
            else throw new Error("ID not returned from Backend");
          } else {
            throw new Error("Something went wrong");
          }
        } else {
          alert("Invalid Name or Operation Name");
        }
      } catch (e) {
        setResponseStatus({
          // @ts-ignore
          success: false,
          // @ts-ignore
          message: e.message || "Something went wrong",
        });
      } finally {
        setTimeout(() => setResponseStatus(null), 5000);
      }
    },
    [navigate]
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // const [res] =
    await promiseHandler(() =>
      registerNewAPICaller({
        name: type === "graphql" ? `${name}/${operationName}` : name,
        method,
        type,
        description,
        route_url,
        parameters,
        workspace_id: localStorage.getItem("activeProject"),
      })
    );
  };

  return (
    <>
      <Header title="Register New API" bigHeader />
      {/* Page content */}
      <ApiForm
        name={name}
        setName={setName}
        method={method}
        setMethod={setMethod}
        type={type}
        setType={setType}
        operationName={operationName}
        setOperationName={setOperationName}
        description={description}
        setDescription={setDescription}
        route_url={route_url}
        setRoute_url={setRoute_url}
        parameters={parameters}
        setParameters={setParameters}
        handleSubmit={handleSubmit}
        ctaText="Create API"
      />
      {responseStatus && (
        <div className="pl-lg-4">
          <Alert color={responseStatus.success ? "success" : "danger"}>
            {responseStatus.message}
          </Alert>
        </div>
      )}
    </>
  );
};

export default RegisterAPI;
