import { Link } from "react-router-dom";
import Styles from "./floating-button.module.scss";

const FloatingButton = ({ text, link, title = "", version = "v1" }: IFloatingButton) => {
  return (
    <Link
      className={
        version === "v1"
          ? Styles["floating-button"]
          : Styles["floating-button-v2"]
      }
      to={link}
      title={title}
    >
      {" "}
      {text}{" "}
    </Link>
  );
};

export default FloatingButton;
