import ModifyMock from "views/Mocks/ModifyMock";
import RegisterMock from "views/Mocks/RegisterMock";
import ViewMock from "views/Mocks/ViewMock";

const mockRoutes: Iroute[] = [
  {
    path: "/mock/:apiId/register",
    name: "Register Mock",
    icon: "ni ni-tv-2 text-primary",
    component: <RegisterMock />,
    layout: "/admin",
  },
  {
    path: "/mock/:apiId/modify/:mock_version",
    name: "Modify API Mock",
    icon: "ni ni-tv-2 text-primary",
    component: <ModifyMock />,
    layout: "/admin",
  },
  {
    path: "/mock/:apiId/view/:mock_version",
    name: "View API",
    icon: "ni ni-tv-2 text-primary",
    component: <ViewMock />,
    layout: "/admin",
  },
];

export default mockRoutes;
