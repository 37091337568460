// core components
import { promiseHandler } from "modules/formatters";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import {
  editRegisteredProject,
  getOneRegisteredProject,
} from "services/http-service";

const EditProject = ({ projectId = null }: IEditProject) => {
  const [responseStatus, setResponseStatus] = useState<any>(null);
  const [newProjectName, setNewProjectName] = useState("");
  const [existingProjectName, setExistingProjectName] = useState("");
  const [newProjectDescription, setNewProjectDescription] = useState("");

  useEffect(() => {
    const asyncCaller = async () => {
      await promiseHandler(() => getProjects());
    };
    asyncCaller();
  }, [projectId]);

  const getProjects = useCallback(() => {
    async function wrapper() {
      const response = await getOneRegisteredProject(projectId as string);
      // TODO: Add toast
      setNewProjectName(response?.data?.name || "");
      setExistingProjectName(response?.data?.name || "");
      setNewProjectDescription(response?.data?.description || "");
      return response;
    }
    wrapper();
  }, [projectId]);

  // Edit Project

  const editProjectCaller = useCallback(async (data: any) => {
    try {
      const response = await editRegisteredProject(projectId as string, data);

      if (response.status >= 200 && response.status < 300) {
        setResponseStatus({
          success: true,
          message: "Project Edited successfully",
        });
      } else {
        throw new Error("Something went wrong");
      }
    } catch (e: any) {
      setResponseStatus({
        success: false,
        message: e.message || "Something went wrong",
      });
    } finally {
      setTimeout(() => setResponseStatus(null), 5000);
    }
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const [res] = await promiseHandler(() =>
      editProjectCaller({
        name: newProjectName,
        description: newProjectDescription,
      })
    );
  };

  return (
    <>
      <Row>
        <Col>
          <Card className="bg-default shadow">
            <CardHeader className="bg-transparent border-0">
              <h3 className="text-white mb-0">
                Edit Project: <code> {existingProjectName} </code>
              </h3>
            </CardHeader>
            <CardBody>
              <FormGroup>
                <Label for="projectName">Project Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="projectName"
                  placeholder="Project Name"
                  value={newProjectName}
                  onChange={(e) => setNewProjectName(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="projectDescription">Project Description</Label>
                <Input
                  type="text"
                  name="description"
                  id="projectDescription"
                  placeholder="Project Description"
                  value={newProjectDescription}
                  onChange={(e) => setNewProjectDescription(e.target.value)}
                />
              </FormGroup>
              <Button onClick={handleSubmit}>Update This Project</Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EditProject;
