import axios from "axios";
import Cookies from "js-cookie";
import React, { useCallback, useEffect, useState } from "react";
import { logUserOut } from "../services/http-service";

export const LoginContext = React.createContext<any>({});

LoginContext.displayName = "LoginContext";

function LoginProvider(props: any) {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<any>({});
  const [isValid, setInvalid] = useState<boolean>(true);
  const [token, setToken] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const logout = useCallback(async () => {
    try {
      await logUserOut();
      document.cookie = "ApiGWAuthToken=; expires=;";
      localStorage.removeItem("ApiGWExpiry");
      Cookies.remove("ApiGWAuthToken");
      setLoginState(false, null, {}, true, "");
    } catch (e: any) {
      setErrorMessage(e.message || "Something went wrong");
    }
  }, []);

  useEffect(() => {
    let diff;
    if (localStorage.getItem("ApiGWExpiry") !== null) {
      // @ts-ignore
      let expiry = new Date(localStorage.getItem("ApiGWExpiry"));
      let today = new Date();
      diff = expiry.getTime() - today.getTime();
    }
    if (
      Cookies.get("ApiGWAuthToken") &&
      (Cookies.get("ApiGWAuthToken") as string).length !== 0
    ) {
      let userName = localStorage.getItem("UserName");
      setLoginState(true, token, userName, false, "");
      // window.setTimeout(() => {
      //   // logout();
      // }, diff);
    } else {
      setLoginState(false, null, {}, false, "");
      logout();
    }
  }, [logout, token]);

  const login = async (username: string, password: string) => {
    console.log(process.env.REACT_APP_BASE_URL);
    try {
      let params = {
        grant_type: "",
        username: username,
        password: password,
        scope: "",
        client_id: "",
        client_secret: "",
      };
      let data = Object.keys(params)
        // @ts-ignore
        .map((key) => key + "=" + params[key])
        .join("&");

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/auth/login`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      const response = await axios(config);

      // console.log("---------------")
      // console.log("data", response.data);
      // console.log("status", response.status);
      // console.log("statusText", response.statusText);
      // console.log("headers", response.headers);
      // console.log("config", response.config);
      // console.log("---------------")

      const token = response.headers["access-token"];

      document.cookie = `ApiGWAuthToken=${token}; expires=${new Date(
        response.data.exp
      )}`;
      localStorage.setItem("ApiGWExpiry", token);
      localStorage.setItem("UserName", username);
      if (token) {
        setLoginState(true, token, username, false);
      } else {
        setLoginState(false, null, null, false);
        // @ts-ignore
        throw new Error(response);
      }
      // throw new Error(response)
    } catch (error) {
      setInvalid(false);
      // @ts-ignore
      localStorage.removeItem("ApiGWExpiry", token);
      // @ts-ignore
      localStorage.removeItem("UserName", username);
      // @ts-ignore
      setLoginState(false, null, null, false, error.message);
      // console.log("Failed to signIn", error);
    }
  };

  const setLoginState = (
    loggedIn: boolean,
    token: string | null,
    user: string | null | object,
    isValid: boolean,
    errorMessage?: any
  ) => {
    setUser(user);
    setToken(token);
    setLoggedIn(loggedIn);
    setInvalid(isValid);
    setErrorMessage(errorMessage);
  };

  const state = {
    login,
    logout,
    loggedIn,
    user,
    isValid,
    token,
    errorMessage,
  };
  return (
    <LoginContext.Provider value={state}>
      {props.children}
    </LoginContext.Provider>
  );
}

export default LoginProvider;
