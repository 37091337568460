import Header from "components/Headers/Header";
import ApiForm from "components/forms/ApiForm";
import { promiseHandler } from "modules/formatters";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Alert
} from "reactstrap";
import {
  getOneRegisteredAPI,
  patchOneRegisteredAPI,
} from "services/http-service";
import { NAME_VALIDATION_REGEX } from "utils";

const ModifyAPI = () => {
  const [name, setName] = useState("");
  const [method, setMethod] = useState("GET");
  const [type, setType] = useState("rest");
  const [operationName, setOperationName] = useState("");
  const [description, setDescription] = useState("");
  const [route_url, setRoute_url] = useState("");
  const [responseStatus, setResponseStatus] = useState<any>(null);
  // @ts-ignore
  const [parameters, setParameters] = useState<Iparameter[]>([{}]);
  const { id } = useParams();

  const updateAPICaller = useCallback(
    async (data: any) => {
      try {
        if (
          NAME_VALIDATION_REGEX.test(data.name) &&
          (type === "graphql"
            ? NAME_VALIDATION_REGEX.test(data.operationName)
            : true)
        ) {
          const response = await patchOneRegisteredAPI(
            // @ts-ignore
            id,
            data
          );

          if (response.status >= 200 && response.status < 300) {
            setResponseStatus({
              // @ts-ignore
              success: true,
              message: "API Added successfully",
            });
          } else {
            throw new Error("Something went wrong");
          }
        } else {
          alert("Invalid Name or Operation Name");
        }
      } catch (e) {
        setResponseStatus({
          // @ts-ignore
          success: false,
          // @ts-ignore
          message: e.message || "Something went wrong",
        });
      } finally {
        setTimeout(() => setResponseStatus(null), 5000);
      }
    },
    [id]
  );

  useEffect(() => {
    const asyncCaller = async () => {
      // @ts-ignore
      const response = await getOneRegisteredAPI(id);
      const { name, method, description, route_url, type, parameters } =
        response.data[0];
      let finalName = name;
      let operationName = "";
      if (type === "graphql") {
        const split = name.split("/");
        operationName = split.pop();
        finalName = split.join("/");
      }
      // type === "graphql" ? `${name}/${operationName}` : name
      setName(finalName);
      setMethod(method);
      setDescription(description);
      setType(type || "rest");
      setOperationName(operationName);
      setRoute_url(route_url);
      setParameters(parameters);
    };
    asyncCaller();
  }, [id]);

  const handleSubmit = async (e: any) => {
    const data = {
      name: type === "graphql" ? `${name}/${operationName}` : name,
      method,
      type,
      description,
      route_url,
      parameters,
      workspace_id: localStorage.getItem("activeProject"),
    };
    e.preventDefault();
    // console.log(data);
    const [res] = await promiseHandler(() => updateAPICaller(data));
  };

  return (
    <>
      <Header title="Modify API" bigHeader />
      {/* Page content */}
      <ApiForm
        name={name}
        setName={setName}
        method={method}
        setMethod={setMethod}
        type={type}
        setType={setType}
        operationName={operationName}
        setOperationName={setOperationName}
        description={description}
        setDescription={setDescription}
        route_url={route_url}
        setRoute_url={setRoute_url}
        parameters={parameters}
        setParameters={setParameters}
        handleSubmit={handleSubmit}
        ctaText="Update this API"
      />
      {responseStatus && (
        <div className="pl-lg-4">
          <Alert color={responseStatus.success ? "success" : "danger"}>
            {responseStatus.message}
          </Alert>
        </div>
      )}
    </>
  );
};

export default ModifyAPI;
